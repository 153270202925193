<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='555'>
                    <col width='170'>
                    <col width='170'>
                    <col width='160'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">记录的卡片名称</th>
                        <th>选中的内容</th>
                        <th>内容</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="item  in data" :key="item.Id">
                        <td>
                            <div class="tdtitle dot" @click="goDetail(item)">{{ item.Title }}</div>
                        </td>
                        <td class="center">
                            <div class="dot2" >{{ item.Txt }}</div>
                            
                        </td>
                        <td class="center">
                            {{ item.Note }}
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="goDetail(item)">详情</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0" />
        </a-spin>
        <Pagination :pageSize="params.pageSize" :total="total" @pageChange="pageChange" v-show="total > 10" />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { mapState } from 'vuex'
import { GetMyNoteList } from '@/api/user'
export default {
    metaInfo() {
        return {
            title: '下载记录-个人中心-文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: '下载记录-个人中心-文物出版社'
                },
                {
                    name: 'description',
                    content: '下载记录-个人中心-文物出版社'
                }
            ]
        }
    },
    components: {
        Pagination
    },
    data() {
        return {
            data: [],
            params: {
                pageIndex: 1,
                pageSize: 10,
            },
            total: 10,
            spinning: false,
        }
    },
    computed: {
        ...mapState({
            downloadType: (state) => state.app.downloadType
        })
    },
    methods: {
        pageChange() {
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({ behavior: "auto" })
        },
        async getList() {
            this.spinning = true
            const { data, total } = await GetMyNoteList(this.params)
            console.log(data);
            this.data = data
            this.total = total
            this.spinning = false
        },
        goDetail(item) {
            this.$router.push({ name: 'card-info', params: { id: item.CardId },query:{modelId:item.ModelId} })
        }
    },
    mounted() {
        this.getList()
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-spin-nested-loading {
    padding: 0 40px;
}

.model {

    // padding: 0 40px;
    .table {
        border-top: 0;
    }
}

.th1 {
    text-align: left !important;
    padding-left: 30px;
}

td {
    height: 60px !important;
    
    &:nth-of-type(2){
        width: 500px;
    }
}

.center {
    text-align: center;
}

.tdtitle {
    // width: 200px;
    cursor: pointer;

    &:hover {
        color: #f6a75c;
    }
}
</style>