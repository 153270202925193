<template>
    <div class="mynotes">
        <Notes/>
    </div>
</template>

<script>
import Notes from './components/Notes.vue'
    export default{
        components:{
            Notes
        },
        data(){
            return {
                params:{
                    pageIndex: 1,
                    pageSize: 8
                }
            }
        },
        methods:{
            getList(){

            }
        }
    }
</script>

<style lang="scss" scoped></style>